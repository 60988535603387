import { defineStore } from 'pinia';

export const useTabStore = defineStore('tab', {
  state: () => ({
    defaultTab: null as string | null,
  }),
  actions: {
    setDefaultTab(tab: string) {
      this.defaultTab = tab;
    },
    clearDefaultTab() {
      this.defaultTab = null;
    }
  }
});
